<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Individual OJT Assessment Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="indOjt">
          <div class="card-body">
            <h3 class="mt-3" v-if="pdfLoading">Individual OJT Assessment</h3>
            <div class="d-flex" v-if="!pdfLoading">
              <label class="col-form-label me-1">Program Name</label>
              <div style="width: 230px">
                <v-select
                  label="name"
                  :options="programs"
                  v-model="program"
                  :clearable="false"
                  @option:selected="getBatchByProgram(program)"
                  :selectable="(options) => options.id != program.id"
                >
                </v-select>
              </div>

              <label class="col-form-label me-1 ms-3">Batch</label>
              <div style="width: 150px">
                <v-select
                  :clearable="false"
                  label="batch_no"
                  :options="batches"
                  v-model="batch"
                  :selectable="(options) => options.id != batch.id"
                  @option:selected="getEmployeeByBatch(program.id, batch.id)"
                >
                </v-select>
              </div>

              <label class="col-form-label me-1 ms-3">Assessor</label>
              <div style="width: 150px">
                <v-select
                  label="assessor_name"
                  :options="assessorList"
                  v-model="assessor"
                  :clearable="false"
                  :selectable="
                    (options) => options.assessor_id != assessor.assessor_id
                  "
                  @option:selected="getAssessorList(assessor.assessor_id)"
                >
                </v-select>
              </div>

              <label class="col-form-label me-1 ms-3">Employee Name</label>
              <div style="width: 150px">
                <v-select
                  :clearable="false"
                  label="employee_name"
                  v-model="employee"
                  :options="employeeLists"
                  :selectable="
                    (options) => options.employee_id != employee.employee_id
                  "
                  @option:selected="getIndividualDetailByEmployee(employee)"
                ></v-select>
              </div>

              <div @click="refreshData" class="icon-css">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div class="mt-4" v-show="!loading && individualOjt" id="indOjt">
              <h5 class="mb-3 p-2 text-white" style="background-color: gray">
                Employee Information
              </h5>
              <div class="row">
                <div class="col-4">
                  <div>
                    Employee Name :
                    <span class="fw-bold">{{
                      individualOjt?.employee.name
                    }}</span>
                  </div>
                  <div>
                    Employee ID Number :
                    <span class="fw-bold">{{
                      individualOjt?.employee_id
                    }}</span>
                  </div>
                  <div>
                    Date of Join :
                    <span class="fw-bold">{{
                      individualOjt?.assessment.batch?.join_date
                    }}</span>
                  </div>
                  <div>
                    Program Name :
                    <span class="fw-bold">{{
                      individualOjt?.assessment.program.name
                    }}</span>
                  </div>
                  <div>
                    Job Title :
                    <span class="fw-bold">{{
                      individualOjt && employee_details?.job_function
                        ? employee_details?.job_function?.name
                        : ""
                    }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div>
                    Program Batch Number :
                    <span class="fw-bold">{{
                      individualOjt?.assessment.batch?.batch_no
                    }}</span>
                  </div>
                  <div>
                    Department :
                    <span class="fw-bold">{{
                      individualOjt ? employee_details?.department?.name : ""
                    }}</span>
                  </div>
                  <div>
                    Section :
                    <span class="fw-bold">{{
                      individualOjt ? employee_details?.department?.name : ""
                    }}</span>
                  </div>
                  <div>
                    Holding Business :
                    <span class="fw-bold">{{
                      individualOjt
                        ? employee_details?.business_unit?.name
                        : ""
                    }}</span>
                  </div>
                </div>

                <div class="col-4">
                  <div>
                    Assessor Name :
                    <span class="fw-bold">{{
                      individualOjt?.assessor?.name
                    }}</span>
                  </div>
                  <div>
                    Assessment Date :
                    <span class="fw-bold">{{ individualOjt?.date }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                Assessment Criteria => Excellent = 5 , Exceed Expection = 4 ,
                Meet Expection = 3 , Need Improvement = 2 , Poor = 1
              </div>

              <div class="mt-3">
                <h5 class="p-2 mb-0 text-white" style="background-color: gray">
                  Main Job & Responsibility
                </h5>
                <quill-editor
                  id="main-quill"
                  theme="snow"
                  v-model:content="main_job_responsibility"
                  disabled
                  :toolbar="toolbarOptions"
                  @blur="true"
                  contentType="html"
                  style="height: 200px; pointer-events: none"
                ></quill-editor>
              </div>

              <div class="mt-3">
                <h5 class="p-2 mb-0 text-white" style="background-color: gray">
                  Behavior Assessment
                </h5>
                <table class="">
                  <thead>
                    <tr>
                      <th class="py-2 text-center" style="width: 100px">No.</th>
                      <th class="py-2 text-center" style="width: 300px">
                        Assessment Points
                      </th>
                      <th class="py-2 text-center" style="width: 100px">
                        Scores
                      </th>
                      <th class="py-2 text-center" style="width: 60%">
                        Detail Description
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="individualOjt">
                    <tr
                      v-for="(item, index) in individualOjt?.hrd_assessments?.filter((item)=>{return item?.hrd_type == 'OJT'})[0]?.hrd_assessment_details"
                    >
                      <td class="text-center">{{ ++index }}</td>
                      <td class="ps-2">{{ item.hrd_criterias.name }}</td>
                      <td class="text-center">{{ item.score }}</td>
                      <td class="ps-2">{{ item.remark }}</td>
                    </tr>
                    <tr>
                      <td class="py-1 text-center" colspan="2">Total Score</td>
                      <td class="text-center">
                        {{
                          individualOjt?.hrd_assessments?.filter((item)=>{return item?.hrd_type == 'OJT'})[0]?.hrd_score  ?? ''
                        }} %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-3">
                <h5 class="p-2 mb-0 text-white" style="background-color: gray">
                  Functional Field Assessment
                </h5>
                <table class="">
                  <thead>
                    <tr>
                      <th class="py-2 text-center" style="width: 100px">No.</th>
                      <th class="py-2 text-center" style="width: 300px">
                        Assessment Points
                      </th>
                      <th class="py-2 text-center" style="width: 100px">
                        Scores
                      </th>
                      <th class="py-2 text-center" style="width: 60%">
                        Detail Description
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="individualOjt">
                    <tr
                      v-for="(item, index) in individualOjt?.hrd_assessments?.filter((item)=>{return item?.hrd_type == 'Functional'})[0]?.hrd_assessment_details"
                    >
                      <td class="text-center">{{ ++index }}</td>
                      <td class="ps-2">{{ item.hrd_criterias.name }}</td>
                      <td class="text-center">{{ item.score }}</td>
                      <td class="ps-2">{{ item.remark }}</td>
                    </tr>
                    <tr>
                      <td class="py-1 text-center" colspan="2">Total Score</td>
                      <td class="text-center">
                        {{
                          individualOjt?.hrd_assessments?.filter((item)=>{return item?.hrd_type == 'Functional'})[0]?.hrd_score  ?? ''
                        }} %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-3">
                <h5 class="p-2 mb-0 text-white" style="background-color: gray">
                  Rule & Regulation  assessment
                </h5>
                <table class="">
                  <thead>
                    <tr>
                      <th class="py-2 text-center" style="width: 100px">No.</th>
                      <th class="py-2 text-center" style="width: 300px">
                        Assessment Points
                      </th>
                      <th class="py-2 text-center" style="width: 100px">
                        Scores
                      </th>
                      <th class="py-2 text-center" style="width: 60%">
                        Detail Description
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="individualOjt">
                    <tr
                      v-for="(item, index) in individualOjt?.hrd_assessments?.filter((item)=>{return item?.hrd_type == 'Rule'})[0]?.hrd_assessment_details"
                    >
                      <td class="text-center">{{ ++index }}</td>
                      <td class="ps-2">{{ item.hrd_criterias.name }}</td>
                      <td class="text-center">{{ item.score }}</td>
                      <td class="ps-2">{{ item.remark }}</td>
                    </tr>
                    <tr>
                      <td class="py-1 text-center" colspan="2">Total Score</td>
                      <td class="text-center" >
                        {{
                          individualOjt?.hrd_assessments?.filter((item)=>{return item?.hrd_type == 'Rule'})[0]?.hrd_score ?? ''
                        }} %
                      </td>
                    </tr>
                    <tr>
                      <td class="py-1 text-center" colspan="4"></td>
                    </tr>

                    <tr>
                      <td class="py-1 text-center" colspan="2">Actual</td>
                      <td class="text-center" colspan="2">
                        {{
                          getActual()
                        }} %
                      </td>
                    </tr>
                    <tr>
                      <td class="py-1 text-center" colspan="2">Final Result</td>
                      <td class="text-center" colspan="2">
                        {{
                          getFinal()
                        }} %
                      </td>
                    </tr>
                    <tr>
                      <td class="py-1 text-center" colspan="2">HRD Recommand</td>
                      <td class="text-center" colspan="2">
                        {{
                          getRecommand()
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axios from "axios";
import { useToast } from "vue-toastification";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { hrd_suggestions } from "../../../utlis/hrd_suggestions";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      pdfLoading: false,
      excelLoading: false,
      loading: false,
      programs: [],
      batches: [],
      employeeLists: [],
      assessorList: [],
      individualOjt: null,
      program: "",
      batch: "",
      employee: "",
      assessor: "",
      main_job_responsibility: "",
      employee_details: "",
      odooEmployeeList: this.$store.getters["odoo/getAllEmployees"],
      title: "Individual OJT Assessment Report",
      toolbarOptions: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      percentagePoint: hrd_suggestions,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },

  methods: {
    async getPrograms() {
      this.loading = true;
      await axios
      .get(`${this.baseUrl}admin/v1/programs`)
      .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getBatchByProgram(program) {
      this.batch = "";
      this.assessor = "";
      this.employee = "";
      this.employeeLists = [];
      this.assessorList = [];
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batches/list?program_id=${program.id}`
        )
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },

    async getEmployeeByBatch(programId, batchId) {
      this.assessor = "";
      this.employeeLists = [];
      this.employee = "";
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/get-assessor-list/${programId}/${batchId}`
        )
        .then((response) => {
          this.assessorList = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },
    getAssessorList(assessorId) {
      this.employee = "";
      this.odooEmployeeList = this.$store.getters["odoo/getAllEmployees"];
      this.assessorList.forEach((assessor) => {
        if (assessor.assessor_id == assessorId)
          this.employeeLists = assessor.employees;
      });
    },
    async getIndividualDetailByEmployee(employee) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/individual-ojt-assessment-report/${this.program.id}/${this.batch.id}/${employee.employee_id}`
        )
        .then((response) => {
          this.individualOjt = response.data.data;
          this.main_job_responsibility =
            this.individualOjt?.main_job_responsibility;
          this.odooEmployeeList.forEach((item) => {
            if (item.emp_id == this.individualOjt?.employee_id)
              return (this.employee_details = item);
          });
        })
        .catch((error) => {
          console.log(error);
          this.toast.error("Something went wrong.");
        });
      this.loading = false;
      this.$Progress.finish();
    },
    exportExcelClick() {
      if (!this.individualOjt)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const data = this.individualOjt;
      const sheetData = [];
      const title2 = "Behavior Assessment";
      const header1 = [
        "Employee ID",
        "Employee Name",
        "Join Date",
        "Program Name",
        "Job Title",
        "Program Batch No",
        "Department",
        "Section",
        "Holding Business",
        "Assessor Name",
        "Assessment Date",
        "Main Job & Responsibility",
      ];
      const header2 = ["No", "Assessment Point", "Score", "Detail Description"];

      sheetData.push(
        header1,
        [
          data.employee.emp_id,
          data.employee.name,
          data.assessment.batch.join_date,
          data.assessment.program.name,
          this.employee_details.job_function?.name,
          data.assessment.batch.batch_no,
          this.employee_details.department?.name,
          this.employee_details.department?.name,
          this.employee_details.business_unit?.name,
          data.assessor.name,
          data.date,
          this.main_job_responsibility
            ? this.stripHtmlTags(this.main_job_responsibility)
            : " - ",
        ],
        [],
        [],
        [], //this is spacing like my
        header2
      );

      let i = 0;
      data.hrd_assessment?.hrd_assessment_details.forEach((item) => {
        const loopItem = [
          ++i,
          item.hrd_criterias.name,
          item.score,
          item.remark,
        ];
        sheetData.push(loopItem);
      });
      sheetData.push([
        "Total Scores",
        data.hrd_assessment?.hrd_score + " %",
      ]);

      exportExcel(sheetData, this.title);
      this.excelLoading = false;
    },
    stripHtmlTags(str) {
      if (str === null || str === "") {
        return false;
      }
      return str.replace(/<[^>]*>/g, "");
    },
    exportPdfClick() {
      this.pdfLoading = true;
      setTimeout(() => {
        const ojt = document.getElementById("indOjt");
        html2canvas(ojt, {
          logging: true,
          letterRendering: 1,
          useCORS: true,
        }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL("img/png");
          const pdf = new jsPDF("p", "mm", "a4");
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
          pdf.save("Individual Ojt Assessment.pdf");
        });
        this.pdfLoading = false;
      }, 1000);
    },
    refreshData() {
      this.program = "";
      this.batch = "";
      this.employee = "";
      this.assessor = "";
      this.batches = [];
      this.programs = [];
      this.employeeLists = [];
      this.assessorList = [];
      this.individualOjt = null;
      this.odooEmployeeList = this.$store.getters["odoo/getAllEmployees"];
      this.getPrograms();
    },
    getActual(){
      let sum = 0;
      this.individualOjt?.hrd_assessments.forEach((item)=>{
        sum = sum + item.hrd_score;
      })

      return ((sum/3)* 0.2).toFixed(2);
    },
    getFinal(){
      let sum = 0;
      this.individualOjt?.hrd_assessments.forEach((item)=>{
        sum = sum + item.hrd_score;
      })
      return (sum/3).toFixed(2);
    },
    getRecommand(){
      const final = this.getFinal();
      let name = 'g';
      this.percentagePoint.forEach((item) => {
          if (
            final <= item.highest_point &&
            final >= item.lowest_point
          ) {
            name = item.name;
          }
        });

      return name;
    }
  },
  created() {
    this.getPrograms();
  },
};
</script>
<style scoped>
table,
th,
td {
  border: 1px solid;
}
</style>
